import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Chip,
  Snackbar,
  Grid,
  Input,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  Divider,
  MenuItem,
  Select,
} from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';

import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { backend } from '../components/user.jsx';
import { Integrations } from '../components/user.jsx';

export function AddArticle() {
  const [article, setArticle] = useState({
    name: '',
    description: '',
    price: '',
    collection: '',
    attributes: [],
    activeStart: '',
    activeEnd: '',
  });
  const [images, setImages] = useState([]);
  const [newAttributeName, setNewAttributeName] = useState('');
  const [newAttributeQuantity, setNewAttributeQuantity] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [selectedIntegrations, setSelectedIntegrations] = useState([]);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    if (id) {
      setIsEditing(true);
      fetchArticle(id);
    }
  }, [location]);

  const integrations = Integrations();

  const fetchArticle = async (id) => {
    try {
      const response = await backend.get(`/shop/admin/articles/${id}`);
      if (response.status === 200 && response.data) {
        const articleData = {
          id: response.data.id || null,
          name: response.data.name || '',
          description: response.data.description || '',
          price: response.data.price?.toString() || '',
          collection: response.data.collection || '',
          attributes: Array.isArray(response.data.attributes) ? response.data.attributes : [],
          activeStart: response.data.activeStart || null,
          activeEnd: response.data.activeEnd || null,
          integrations: Array.isArray(response.data.integrations) ? response.data.integrations : [],
        };

        setSelectedIntegrations(articleData.integrations);
        setArticle(articleData);

        const responseImages = response.data.images;
        if (responseImages && Array.isArray(responseImages)) {
          const existingImages = responseImages
            .filter((img) => img != null)
            .map((img, index) => ({
              name: img.name || `Image ${index + 1}`,
              type: img.type || 'image/jpeg',
              data: img.data || null,
            }));
          setImages(existingImages);
        } else {
          setImages([]);
        }
      } else {
        setArticle({
          name: '',
          description: '',
          price: '',
          collection: '',
          attributes: [],
          activeStart: null,
          activeEnd: null,
          integrations: [],
        });
        setImages([]);
        setSnackbarMessage('No article found or invalid data received');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error fetching article:', error);
      setArticle({
        name: '',
        description: '',
        price: '',
        collection: '',
        attributes: [],
        activeStart: null,
        activeEnd: null,
        integrations: [],
      });
      setImages([]);
      setSnackbarMessage('Error fetching article details: ' + (error.response?.data?.message || error.message));
      setSnackbarOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticle((prevArticle) => ({
      ...prevArticle,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    if (e.target.files) {
      setImages((prevImages) => [...prevImages, ...Array.from(e.target.files)]);
    }
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleAddAttribute = () => {
    if (newAttributeName && newAttributeQuantity) {
      setArticle((prevArticle) => ({
        ...prevArticle,
        attributes: [
          ...prevArticle.attributes.filter((a) => a.name !== newAttributeName),
          { name: newAttributeName, quantity: parseInt(newAttributeQuantity, 10) },
        ],
      }));
      setNewAttributeName('');
      setNewAttributeQuantity('');
    }
  };

  const handleEditAttribute = (attributeName) => {
    const attribute = article.attributes.find((a) => a.name === attributeName);
    if (attribute) {
      setNewAttributeName(attribute.name);
      setNewAttributeQuantity(attribute.quantity.toString());
      handleRemoveAttribute(attributeName);
    }
  };

  const handleRemoveAttribute = (attributeToRemove) => {
    setArticle((prevArticle) => ({
      ...prevArticle,
      attributes: prevArticle.attributes.filter((a) => a.name !== attributeToRemove),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Validate that the end date is greater than or equal to the start date
      if (article.activeStart && article.activeEnd && dayjs(article.activeEnd).isBefore(dayjs(article.activeStart))) {
        setSnackbarMessage('End date must be greater than or equal to the start date.');
        setSnackbarOpen(true);
        return;
      }

      // Create a copy of the article object
      const articleData = { ...article };

      // Remove integrations if empty
      if (!articleData.integrations?.length) {
        delete articleData.integrations;
      }

      // Convert price to float
      articleData.price = parseFloat(articleData.price);

      // Check if price is a valid number
      if (isNaN(articleData.price)) {
        setSnackbarMessage('Invalid price. Please enter a valid number.');
        setSnackbarOpen(true);
        return;
      }

      // Convert images to binary only for new articles
      if (images && images.length > 0) {
        articleData.images = await Promise.all(
          images.map(async (image) => {
            // If the image already has a data property (existing image), return it as is
            if (image.data) {
              return image;
            }
            // Only encode new images
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () =>
                resolve({
                  name: image.name,
                  type: image.type,
                  data: reader.result.split(',')[1],
                });
              reader.onerror = reject;
              reader.readAsDataURL(image);
            });
          })
        );
      }

      let response;
      if (isEditing) {
        response = await backend.put(`/shop/admin/articles/${article.id}`, articleData);

        if (response.status === 200 || response.status === 201) {
          setSnackbarMessage(isEditing ? 'Article updated successfully' : 'Article added successfully');
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage(isEditing ? 'Failed to update article' : 'Failed to add article');
          setSnackbarOpen(true);
        }
      } else {
        response = await backend.post('/shop/admin/articles/', articleData);

        if (response.status === 201) {
          setSnackbarMessage('Article added successfully');
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage('Failed to add article');
          setSnackbarOpen(true);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setSnackbarMessage('Failed to process article');
      setSnackbarOpen(true);
    }
  };

  const handleDateChange = (field) => (date) => {
    setArticle((prevArticle) => ({
      ...prevArticle,
      [field]: date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null,
    }));
  };

  const handleIntegrationChange = (event) => {
    setSelectedIntegrations(event.target.value);
    setArticle((prevArticle) => ({
      ...prevArticle,
      integrations: event.target.value,
    }));
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ mb: 4 }}>
          {isEditing ? 'Edit Article' : 'Add New Article'}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={article.name}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Price"
                name="price"
                type="number"
                value={article.price}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={article.description}
                onChange={handleChange}
                multiline
                rows={4}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Collection (Optional)"
                name="collection"
                value={article.collection}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Box my={4}>
            <Typography variant="h6" gutterBottom>
              Attributes and Quantities
            </Typography>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={5}>
                <TextField
                  fullWidth
                  label="Attribute Name"
                  value={newAttributeName}
                  onChange={(e) => setNewAttributeName(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  fullWidth
                  label="Quantity"
                  type="number"
                  value={newAttributeQuantity}
                  onChange={(e) => setNewAttributeQuantity(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button onClick={handleAddAttribute} variant="contained" fullWidth>
                  Add
                </Button>
              </Grid>
            </Grid>
            <Box display="flex" flexWrap="wrap" mt={2}>
              {article.attributes.map(({ name, quantity }) => (
                <Chip
                  key={name}
                  label={`${name} (${quantity})`}
                  onDelete={() => handleRemoveAttribute(name)}
                  onClick={() => handleEditAttribute(name)}
                  sx={{ m: 0.5, cursor: 'pointer' }}
                />
              ))}
            </Box>
          </Box>

          <Divider sx={{ my: 4 }} />

          <Box my={4}>
            <Typography variant="h6" gutterBottom>
              Images
            </Typography>
            <Button variant="outlined" component="label" startIcon={<AddPhotoAlternateIcon />} sx={{ mb: 2 }}>
              Upload Images
              <Input
                type="file"
                onChange={handleImageChange}
                sx={{ display: 'none' }}
                inputProps={{ accept: 'image/*', multiple: true }}
              />
            </Button>
            <List>
              {images.map((image, index) => (
                <ListItem key={index}>
                  <ListItemText primary={image.name} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveImage(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>

          <Divider sx={{ my: 4 }} />

          <Box my={4}>
            <Typography variant="h6" gutterBottom>
              Active Period
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Start Date"
                    value={article.activeStart ? dayjs(article.activeStart) : null}
                    onChange={handleDateChange('activeStart')}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="End Date"
                    value={article.activeEnd ? dayjs(article.activeEnd) : null}
                    onChange={handleDateChange('activeEnd')}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Box>

          {/* Add this new section before the submit button */}
          <Box my={4}>
            <Typography variant="h6" gutterBottom>
              Integrations (optional)
            </Typography>
            <Typography variant="h8" gutterBottom>
              If omitted, the article will not be available for all integrations.
            </Typography>
            <Select
              multiple
              fullWidth
              value={selectedIntegrations}
              onChange={handleIntegrationChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} sx={{ backgroundColor: integrations[value]?.color }} />
                  ))}
                </Box>
              )}
            >
              {Object.entries(integrations).map(([name, integration]) => (
                <MenuItem key={name} value={name}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        borderRadius: '50%',
                        backgroundColor: integration.color,
                      }}
                    />
                    {name}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            sx={{ mt: 4, mb: 2, py: 1.5 }}
          >
            {isEditing ? 'Update Article' : 'Add Article'}
          </Button>
        </form>
      </Paper>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Container>
  );
}
