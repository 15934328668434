import { backend } from '../components/user.jsx';
import { useLoaderData } from 'react-router-dom';
import { TimeReportsLayout } from '../components/TimeReports.jsx';

export const TimeReports = () => {
    const timeReports = useLoaderData() || [];

    return TimeReportsLayout(timeReports);
}

export const timeReportsLoader = async () => {
  try {
    const response = await backend.get('/my/time-reports');
    if (response.status !== 200) {
      throw new Response("Failed to fetch time reports", {
        status: response.status,
        statusText: response.statusText
      });
    }
    return response.data;
  } catch (err) {
    if (err?.status) {
      throw new Response("", { status: err.status });
    }
    throw err;
  }
};