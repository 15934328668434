import React, { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Box,
  Collapse,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Button,
  Alert,
  Snackbar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { backend, IntegrationColor } from '../components/user.jsx';
import { getRequestStatusColor } from '../utils/theme.jsx';

const StyledTableCell = styled(TableCell)(() => ({
  fontWeight: 'bold',
}));

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

const calculateQuantity = (attributes) => attributes.reduce((sum, attribute) => sum + attribute.quantity, 0);

function AllOrdersRow(props) {
  const { order, onStatusChange } = props;
  const [open, setOpen] = useState(false);

  const totalAmount = order.articles.reduce(
    (sum, article) => sum + article.price * calculateQuantity(article.attributes),
    0
  );

  const renderStatusButtons = () => {
    switch (order.status) {
      case 'pending':
        return (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={() => onStatusChange(order.id, 'accepted')}
              sx={{ mr: 1, bgcolor: '#90EE90', '&:hover': { bgcolor: '#7CCD7C' } }}
            >
              Accept
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => onStatusChange(order.id, 'rejected')}
              sx={{ bgcolor: '#FF6B6B', '&:hover': { bgcolor: '#FF4040' } }}
            >
              Reject
            </Button>
          </>
        );
      case 'accepted':
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => onStatusChange(order.id, 'completed')}
            sx={{ bgcolor: '#90EE90', '&:hover': { bgcolor: '#7CCD7C' } }}
          >
            Complete
          </Button>
        );
      case 'rejected':
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => onStatusChange(order.id, 'delete')}
            sx={{ bgcolor: '#FF6B6B', '&:hover': { bgcolor: '#FF4040' } }}
          >
            Delete
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{formatTimestamp(order.timestamp)}</TableCell>
        <TableCell>{order.id}</TableCell>
        <TableCell>{order.customer}</TableCell>
        <TableCell>
          {order.integration ? (
            <Chip
              label={order.integration}
              sx={{ backgroundColor: IntegrationColor(order.integration) }}
              size="small"
            />
          ) : (
            <Chip
              label="Group"
              sx={{ backgroundColor: IntegrationColor('group') }}
              size="small"
            />
          )}
        </TableCell>
        <TableCell>
          <Chip label={order.status} sx={{ backgroundColor: getRequestStatusColor(order.status) }} size="small" />
        </TableCell>
        <TableCell align="right">{totalAmount.toFixed(2)}</TableCell>
        <TableCell>{renderStatusButtons()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Articles
              </Typography>
              <Table size="small" aria-label="articles">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Article Name</StyledTableCell>
                    <StyledTableCell>Collection</StyledTableCell>
                    <StyledTableCell>Attributes</StyledTableCell>
                    <StyledTableCell align="right">Price</StyledTableCell>
                    <StyledTableCell align="right">Total</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.articles.map((article) => (
                    <TableRow key={article.id}>
                      <TableCell component="th" scope="row">
                        {article.name}
                      </TableCell>
                      <TableCell>{article.collection}</TableCell>
                      <TableCell>
                        {article.attributes.map((attr) => (
                          <Chip
                            key={attr.id}
                            label={`${attr.name}: ${attr.quantity}`}
                            size="small"
                            sx={{ margin: '2px' }}
                          />
                        ))}
                      </TableCell>
                      <TableCell align="right">{article.price.toFixed(2)}</TableCell>
                      <TableCell align="right">
                        {(article.price * calculateQuantity(article.attributes)).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Row(props) {
  const { row, orders } = props; // Add orders to props

  // Only count quantities from non-rejected orders
  const totalQuantity = row.attributes.reduce((sum, attribute) => {
    return (
      sum +
      attribute.customers.reduce((customerSum, customer) => {
        // Check if this customer's order is in the rejected orders list
        const isRejected = orders.find((o) => o.id === customer.orderId)?.status === 'rejected';
        return customerSum + (isRejected ? 0 : customer.quantity);
      }, 0)
    );
  }, 0);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {row.articleId}
      </TableCell>
      <TableCell>{row.articleName}</TableCell>
      <TableCell>{row.collection}</TableCell>
      <TableCell>{totalQuantity}</TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {row.attributes.map((attribute) => {
            // Calculate quantity excluding rejected orders
            const attributeQuantity = attribute.customers.reduce((sum, customer) => {
              const isRejected = orders.find((o) => o.id === customer.orderId)?.status === 'rejected';
              return sum + (isRejected ? 0 : customer.quantity);
            }, 0);

            return <Chip key={attribute.name} label={`${attribute.name}: ${attributeQuantity}`} size="small" />;
          })}
        </Box>
      </TableCell>
    </TableRow>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function CustomerRow(props) {
  const { customer } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {customer.customer}
        </TableCell>
        <TableCell>
          {customer.integration ? (
            <Chip
              label={customer.integration}
              sx={{ backgroundColor: IntegrationColor(customer.integration) }}
              size="small"
            />
          ) : (
            <Chip
              label="Group"
              sx={{ backgroundColor: IntegrationColor('group') }}
              size="small"
            />
          )}
        </TableCell>
        <TableCell align="right">{customer.totalOrders}</TableCell>
        <TableCell align="right">{customer.totalAmount.toFixed(2)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Orders
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Order ID</TableCell>
                    <TableCell>Status</TableCell> {/* Add this line */}
                    <TableCell>Article</TableCell>
                    <TableCell>Collection</TableCell>
                    <TableCell>Attributes</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customer.orders.flatMap((order) =>
                    order.articles.map((article, index) => (
                      <TableRow key={`${order.orderId}-${article.id}`}>
                        <TableCell>{index === 0 ? formatTimestamp(order.timestamp) : ''}</TableCell>
                        <TableCell>{index === 0 ? order.orderId : ''}</TableCell>
                        <TableCell>
                          {index === 0 ? (
                            <Chip
                              label={order.status}
                              sx={{ backgroundColor: getRequestStatusColor(order.status) }}
                              size="small"
                            />
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell>{article.name}</TableCell>
                        <TableCell>{article.collection}</TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {article.attributes.map((attr) => (
                              <Chip key={attr.id} label={`${attr.name}: ${attr.quantity}`} size="small" />
                            ))}
                          </Box>
                        </TableCell>
                        <TableCell align="right">{(article.price * article.quantity).toFixed(2)}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function Orders() {
  const orders = useLoaderData() || [];
  const [tabValue, setTabValue] = useState(0);
  const [selectedCollection, setSelectedCollection] = useState(() => {
    return localStorage.getItem('selectedCollection') || 'All';
  });
  const [selectedIntegration, setSelectedIntegration] = useState(() => {
    return localStorage.getItem('selectedIntegration') || 'All';
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCollectionChange = (event) => {
    const newCollection = event.target.value;
    setSelectedCollection(newCollection);
    localStorage.setItem('selectedCollection', newCollection);
  };

  const handleIntegrationChange = (event) => {
    const newIntegration = event.target.value;
    setSelectedIntegration(newIntegration);
    localStorage.setItem('selectedIntegration', newIntegration);
  };

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      if (newStatus === 'delete') {
        const response = await backend.delete(`/shop/admin/orders/${orderId}`);
        if (response.status === 200) {
          setSuccessMessage(`Order ${orderId} has been deleted`);
          window.location.reload();
        } else {
          setErrorMessage(`Failed to delete order ${orderId}`);
        }
      } else {
        const response = await backend.put(`/shop/admin/orders/${orderId}/status`, { status: newStatus });
        if (response.status === 200) {
          setSuccessMessage(`Order ${orderId} status updated to ${newStatus}`);
          window.location.reload();
        } else {
          setErrorMessage(`Failed to update order ${orderId} status to ${newStatus}`);
        }
      }
    } catch (err) {
      setErrorMessage(`Failed to update order: ${err.message}`);
    }
  };

  // Updated filtering logic to include both collection and integration
  const filteredOrders = orders
    .filter(order => {
      const matchesIntegration = selectedIntegration === 'All' || 
        (order.integration === selectedIntegration || 
        (!order.integration && selectedIntegration === 'group'));
      
      if (!matchesIntegration) return false;

      if (selectedCollection === 'All') return true;
      return order.articles.some(article => article.collection === selectedCollection);
    })
    .map(order => {
      if (selectedCollection === 'All') return order;
      return {
        ...order,
        articles: order.articles.filter(article => article.collection === selectedCollection),
      };
    });

  // Sort the filteredOrders in descending order based on timestamp
  const sortedOrders = [...filteredOrders].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  const articlesData = sortedOrders
    .flatMap((order) =>
      order.articles.map((article) => ({
        articleId: article.id,
        articleName: article.name,
        collection: article.collection,
        attributes: article.attributes,
        customer: order.customer,
        timestamp: order.timestamp,
        orderId: order.id,
      }))
    )
    .reduce((acc, curr) => {
      const existingArticle = acc.find((a) => a.articleId === curr.articleId);
      if (existingArticle) {
        curr.attributes.forEach((attribute) => {
          const existingAttribute = existingArticle.attributes.find((a) => a.name === attribute.name);
          if (existingAttribute) {
            existingAttribute.totalQuantity += attribute.quantity;
            existingAttribute.customers.push({
              customer: curr.customer,
              timestamp: curr.timestamp,
              orderId: curr.orderId,
              quantity: attribute.quantity,
            });
          } else {
            existingArticle.attributes.push({
              name: attribute.name,
              totalQuantity: attribute.quantity,
              customers: [
                {
                  customer: curr.customer,
                  timestamp: curr.timestamp,
                  orderId: curr.orderId,
                  quantity: attribute.quantity,
                },
              ],
            });
          }
        });
      } else {
        acc.push({
          articleId: curr.articleId,
          articleName: curr.articleName,
          collection: curr.collection,
          attributes: curr.attributes.map((attribute) => ({
            name: attribute.name,
            totalQuantity: attribute.quantity,
            customers: [
              {
                customer: curr.customer,
                timestamp: curr.timestamp,
                orderId: curr.orderId,
                quantity: attribute.quantity,
              },
            ],
          })),
        });
      }
      return acc;
    }, []);

  // Update the sorting function to handle both string and number types
  articlesData.sort((a, b) => {
    if (typeof a.articleId === 'string' && typeof b.articleId === 'string') {
      return b.articleId.localeCompare(a.articleId);
    } else {
      return b.articleId - a.articleId;
    }
  });

  // Get unique collections
  const collections = [
    'All',
    ...new Set(orders.flatMap((order) => order.articles.map((article) => article.collection))),
  ];

  const customersData = sortedOrders.reduce((acc, order) => {
    const totalAmount = order.articles.reduce(
      (sum, article) => sum + article.price * calculateQuantity(article.attributes),
      0
    );
    const existingCustomer = acc.find((c) => c.customer === order.customer);
    if (existingCustomer) {
      existingCustomer.orders.push({
        orderId: order.id,
        customer: order.customer,
        timestamp: order.timestamp,
        status: order.status, // Add this line
        totalAmount: totalAmount,
        articles: order.articles.map((article) => ({
          ...article,
          quantity: calculateQuantity(article.attributes),
        })),
      });
      existingCustomer.totalOrders += 1;
      existingCustomer.totalAmount += totalAmount;
    } else {
      acc.push({
        customer: order.customer,
        integration: order.integration,
        totalOrders: 1,
        totalAmount: totalAmount,
        orders: [
          {
            orderId: order.id,
            customer: order.customer,
            timestamp: order.timestamp,
            status: order.status, // Add this line
            totalAmount: totalAmount,
            articles: order.articles.map((article) => ({
              ...article,
              quantity: calculateQuantity(article.attributes),
            })),
          },
        ],
      });
    }
    return acc;
  }, []);

  // Sort customersData by most recent order
  customersData.sort((a, b) => new Date(b.orders[0].timestamp) - new Date(a.orders[0].timestamp));

  // Get unique integrations
  const integrations = [
    'All',
    ...new Set(orders.map(order => order.integration || 'group')),
  ];

  return (
    <Container maxWidth="lg" sx={{ paddingTop: 4 }}>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorMessage(null)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSuccessMessage(null)} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="collection-select-label">Collection</InputLabel>
          <Select
            labelId="collection-select-label"
            id="collection-select"
            value={selectedCollection}
            label="Collection"
            onChange={handleCollectionChange}
          >
            {collections.map((collection) => (
              <MenuItem key={collection} value={collection}>
                {collection}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="integration-select-label">Company</InputLabel>
          <Select
            labelId="integration-select-label"
            id="integration-select"
            value={selectedIntegration}
            label="Company"
            onChange={handleIntegrationChange}
          >
            <MenuItem value="All">All</MenuItem>
            {integrations.filter(i => i !== 'All').map((integration) => (
              <MenuItem key={integration} value={integration}>
                <Chip
                  label={integration === 'group' ? 'Group' : integration}
                  sx={{ 
                    backgroundColor: IntegrationColor(integration),
                    '.MuiSelect-select &&': { backgroundColor: 'transparent' }
                  }}
                  size="small"
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="order tabs">
          <Tab label="All Orders" />
          <Tab label="Articles" />
          <Tab label="Customers" />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>Order Date</StyledTableCell>
                <StyledTableCell>Order ID</StyledTableCell>
                <StyledTableCell>Customer</StyledTableCell>
                <StyledTableCell>Company</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell align="right">Total Amount</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedOrders.length > 0 ? (
                sortedOrders.map((order) => (
                  <AllOrdersRow key={order.id} order={order} onStatusChange={handleStatusChange} />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No orders found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <TableContainer component={Paper}>
          <Table aria-label="articles table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Article ID</StyledTableCell>
                <StyledTableCell>Article Name</StyledTableCell>
                <StyledTableCell>Collection</StyledTableCell>
                <StyledTableCell>Total Quantity</StyledTableCell>
                <StyledTableCell>Attributes</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {articlesData.length > 0 ? (
                articlesData.map((row) => (
                  <Row key={row.articleId} row={row} orders={orders} /> // Pass orders as prop
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No articles found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>Customer</StyledTableCell>
                <StyledTableCell>Company</StyledTableCell>
                <StyledTableCell align="right">Total Orders</StyledTableCell>
                <StyledTableCell align="right">Total Amount</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customersData.length > 0 ? (
                customersData.map((customer) => <CustomerRow key={customer.customer} customer={customer} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No customers found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </Container>
  );
}

export async function ordersLoader() {
  try {
    const response = await backend.get('/shop/admin/orders');
    if (response.status !== 200) {
      throw new Response("Failed to fetch orders", { 
        status: response.status,
        statusText: response.statusText 
      });
    }
    return response.data;
  } catch (err) {
    if (err?.status) {
      throw new Response("", { status: err.status });
    }
    throw err;
  }
}