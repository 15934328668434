import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { EmployeeSalaryCalculation } from '../components/Employee.jsx';
import { backend } from '../components/user.jsx';
import { Grid } from '@mui/material';

export function SalaryCalculation() {
  const me = useLoaderData();

  return (
    <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
      <Grid item xs={12}>
        {EmployeeSalaryCalculation(me)}
      </Grid>
    </Grid>
  );
}

export const salaryCalculationLoader = async () => {
  try {
    const response = await backend.get('/my/profile');
    if (response.status !== 200) {
      throw new Response("Failed to fetch profile data", {
        status: response.status,
        statusText: response.statusText
      });
    }

    return response.data;
  } catch (err) {
    if (err?.status) {
      throw new Response("", { status: err.status });
    }
    throw err;
  }
};