import React from 'react';
import { useRouteError, isRouteErrorResponse, Navigate } from 'react-router-dom';
import { Alert, Container, Typography } from '@mui/material';

export function ErrorBoundary() {
  const error = useRouteError();

  // Handle specific HTTP error responses
  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 401:
        return <Navigate to={'/login'} state={{ from: window.location.pathname }} />;
      case 403:
        return (
          <Container>
            <Alert severity="error">
              You don&apos;t have permission to access this resource
            </Alert>
          </Container>
        );

      case 404:
        return (
          <Container>
            <Alert severity="warning">
              The page you&apos;re looking for doesn&apos;t exist
            </Alert>
          </Container>
        );
      
      default:
        return (
          <Container>
            <Alert severity="error">
              {error.statusText || 'Something went wrong'}
            </Alert>
          </Container>
        );
    }
  }

  // Handle other errors (non-HTTP errors)
  return (
    <Container>
      <Alert severity="error">
        <Typography variant="h6">Oops! Something went wrong</Typography>
        <Typography variant="body2">
          {error instanceof Error ? error.message : 'An unexpected error occurred'}
        </Typography>
      </Alert>
    </Container>
  );
} 