import { useLoaderData } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip as MuiTooltip,
} from '@mui/material';

import { theme } from '../utils/theme.jsx';

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// Add this constant for expected working days
const expectedWorkDays = {
  0: 21, // January
  1: 20, // February
  2: 22, // March
  3: 20, // April
  4: 19, // May
  5: 19, // June
  6: 0, // July
  7: 21, // August
  8: 22, // September
  9: 22, // October
  10: 21, // November
  11: 20, // December
};

// Move the helper function before it's used
const getFirstReportedMonth = (monthlyTotals, year) => {
  for (let month = 0; month < 12; month++) {
    if (monthlyTotals[year]?.[month] && Object.values(monthlyTotals[year][month]).some((entry) => entry.hours > 0)) {
      return month;
    }
  }
  return 0;
};

export const TimeReportsLayout = (timeReports) => {
  // Handle null or empty timeReports
  if (!timeReports || !Array.isArray(timeReports) || timeReports.length === 0) {
    return (
      <Typography variant="h6" align="center" sx={{ mt: 4, color: 'text.secondary' }}>
        No time reports available
      </Typography>
    );
  }

  // First, create all data structures
  const tableData = {
    // Original data structures
    organizedData: {},
    yearlyTotals: {},
    monthlyTotals: {},
    weeklyData: {},
    
    // New structures for the comparison table
    comparisonData: {},
    
    // Initialize the data
    initialize(timeReports) {
      for (const report of timeReports) {
        const date = new Date(report.date);
        const year = date.getFullYear();
        const month = date.getMonth();
        const week = getWeekNumber(date);
        const code = report.code;
        const type = report.type;
        const hours = report.hours;

        // Initialize organizedData structure
        if (!this.organizedData[year]) {
          this.organizedData[year] = {};
        }
        if (!this.organizedData[year][week]) {
          this.organizedData[year][week] = [];
        }
        this.organizedData[year][week].push(report);

        // Initialize all year-based structures
        if (!this.yearlyTotals[year]) this.yearlyTotals[year] = {};
        if (!this.monthlyTotals[year]) this.monthlyTotals[year] = {};
        if (!this.monthlyTotals[year][month]) this.monthlyTotals[year][month] = {};
        if (!this.weeklyData[year]) this.weeklyData[year] = {};
        if (!this.weeklyData[year][month]) this.weeklyData[year][month] = {};
        if (!this.comparisonData[year]) {
          this.comparisonData[year] = {
            expectedHours: Array(12).fill(0),
            cumulativeExpected: Array(12).fill(0),
            actualHours: Array(12).fill(0),
            cumulativeActual: Array(12).fill(0),
            percentages: Array(12).fill(0)
          };
        }

        // Update all totals
        this.updateTotals(year, month, week, code, type, hours);
      }

      // Calculate comparison data for each year
      Object.keys(this.yearlyTotals).forEach(year => {
        this.calculateComparisonData(parseInt(year));
      });

      return this;
    },

    updateTotals(year, month, week, code, type, hours) {
      // Update yearly totals
      const yearKey = `${code}-${type}`;
      if (!this.yearlyTotals[year][yearKey]) {
        this.yearlyTotals[year][yearKey] = { code, type, hours: 0 };
      }
      this.yearlyTotals[year][yearKey].hours += hours;

      // Update monthly totals
      const monthKey = `${code}-${type}`;
      if (!this.monthlyTotals[year][month][monthKey]) {
        this.monthlyTotals[year][month][monthKey] = { code, type, hours: 0 };
      }
      this.monthlyTotals[year][month][monthKey].hours += hours;

      // Update weekly data
      if (!this.weeklyData[year][month][`${code}-${type}`]) {
        this.weeklyData[year][month][`${code}-${type}`] = {};
      }
      if (!this.weeklyData[year][month][`${code}-${type}`][week]) {
        this.weeklyData[year][month][`${code}-${type}`][week] = 0;
      }
      this.weeklyData[year][month][`${code}-${type}`][week] += hours;

      // Update actual hours if type is WORK
      if (type === 'WORK') {
        this.comparisonData[year].actualHours[month] += hours;
      }
    },

    calculateComparisonData(year) {
      const firstMonth = getFirstReportedMonth(this.monthlyTotals, year);
      let cumulativeExpected = 0;
      let cumulativeActual = 0;

      for (let month = 0; month < 12; month++) {
        if (month >= firstMonth) {
          // Expected hours
          const expected = expectedWorkDays[month] * 8;
          this.comparisonData[year].expectedHours[month] = expected;
          
          // Cumulative expected
          cumulativeExpected += expected;
          this.comparisonData[year].cumulativeExpected[month] = cumulativeExpected;
          
          // Cumulative actual
          cumulativeActual += this.comparisonData[year].actualHours[month];
          this.comparisonData[year].cumulativeActual[month] = cumulativeActual;
          
          // Calculate percentage
          this.comparisonData[year].percentages[month] = 
            (cumulativeActual / cumulativeExpected) * 100;
        }
      }
    }
  };

  // Initialize all data
  const data = tableData.initialize(timeReports);

  // Helper function to render weekly details tooltip content
  const WeeklyDetailsTooltip = ({ year, month, code, type }) => {
    const weeklyHours = data.weeklyData[year]?.[month]?.[`${code}-${type}`] || {};

    return (
      <Paper sx={{ p: 1, maxWidth: 300 }}>
        <Typography variant="subtitle2" gutterBottom>
          Weekly Breakdown
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Week</TableCell>
              <TableCell align="right">Hours</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(weeklyHours).map(([week, hours]) => (
              <TableRow key={week}>
                <TableCell>{week}</TableCell>
                <TableCell align="right">{hours.toFixed(1)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  };

  return (
    <>
      {Object.entries(data.organizedData)
        .sort(([yearA], [yearB]) => yearB - yearA)
        .map(([year, weeks]) => (
          <div key={year}>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{
                mt: 4,
                mb: 4,
                color: 'primary.main',
                fontWeight: 'medium',
              }}
            >
              {year}
            </Typography>

            {/* Monthly Summaries */}
            <TableContainer
              component={Paper}
              sx={{
                mb: 4,
                boxShadow: 2,
                borderRadius: 2,
                overflow: 'auto',
                maxWidth: '100vw',
                '& .MuiTable-root': {
                  borderCollapse: 'separate',
                  borderSpacing: '0',
                  minWidth: '1000px',
                },
                '& .MuiTableCell-head': {
                  backgroundColor: '#2e7d32',
                  color: 'white',
                  fontSize: '0.875rem',
                  whiteSpace: 'nowrap',
                },
                '& .MuiTableCell-body': {
                  fontSize: '0.875rem',
                  borderBottom: '1px solid rgba(224, 224, 224, 0.4)',
                  padding: '8px 16px',
                },
                '& .MuiTableRow-root:hover': {
                  backgroundColor: 'rgba(46, 125, 50, 0.04)',
                },
                '& .MuiTableRow-root:last-child .MuiTableCell-body': {
                  borderBottom: 'none',
                },
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: '#2e7d32', color: 'white', fontWeight: 'bold' }}>Code</TableCell>
                    <TableCell sx={{ backgroundColor: '#2e7d32', color: 'white', fontWeight: 'bold' }}>Type</TableCell>
                    {monthNames.map((monthName, index) => (
                      <TableCell 
                        key={monthName} 
                        align="right" 
                        sx={{ backgroundColor: '#2e7d32', color: 'white', fontWeight: 'bold' }}
                      >
                        {monthName}
                      </TableCell>
                    ))}
                    <TableCell 
                      align="right" 
                      sx={{ backgroundColor: '#1b5e20', color: 'white', fontWeight: 'bold' }}
                    >
                      Yearly Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(data.yearlyTotals[year] || {})
                    .sort(([, a], [, b]) => b.hours - a.hours)
                    .map(([key, { code, type, hours }]) => (
                      <TableRow key={`${year}-${code}-${type}`} hover sx={{ '&:last-child td': { borderBottom: 0 } }}>
                        <TableCell>{code}</TableCell>
                        <TableCell>{type}</TableCell>
                        {Array.from({ length: 12 }, (_, month) => (
                          <TableCell key={month} align="right">
                            {data.monthlyTotals[year]?.[month]?.[`${code}-${type}`]?.hours ? (
                              <MuiTooltip
                                theme={theme}
                                title={<WeeklyDetailsTooltip year={year} month={month} code={code} type={type} />}
                                arrow
                                placement="right"
                              >
                                <span>{data.monthlyTotals[year][month][`${code}-${type}`].hours.toFixed(1)}</span>
                              </MuiTooltip>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        ))}
                        <TableCell align="right" sx={{ backgroundColor: 'grey.50', fontWeight: 'medium' }}>
                          {hours.toFixed(1)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Hours Comparison */}
            <TableContainer
              component={Paper}
              sx={{
                mb: 4,
                boxShadow: 2,
                borderRadius: 2,
                overflow: 'auto',
                maxWidth: '100vw',
                '& .MuiTable-root': {
                  borderCollapse: 'separate',
                  borderSpacing: '0',
                  minWidth: '1000px',
                },
                '& .MuiTableCell-head': {
                  backgroundColor: '#2e7d32',
                  color: 'white',
                  fontSize: '0.875rem',
                  whiteSpace: 'nowrap',
                },
                '& .MuiTableCell-body': {
                  fontSize: '0.875rem',
                  borderBottom: '1px solid rgba(224, 224, 224, 0.4)',
                  padding: '8px 16px',
                },
                '& .MuiTableRow-root:hover': {
                  backgroundColor: 'rgba(46, 125, 50, 0.04)',
                },
                '& .MuiTableRow-root:last-child .MuiTableCell-body': {
                  borderBottom: 'none',
                },
                '& .MuiTableCell-body:first-of-type': {
                  fontWeight: 'medium',
                  backgroundColor: 'rgba(46, 125, 50, 0.08)',
                },
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell 
                      sx={{ backgroundColor: '#2e7d32', color: 'white', fontWeight: 'bold' }}
                    >
                      Metric
                    </TableCell>
                    {monthNames.map((monthName, index) => (
                      <TableCell 
                        key={monthName} 
                        align="right" 
                        sx={{ backgroundColor: '#2e7d32', color: 'white', fontWeight: 'bold' }}
                      >
                        {monthName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <MuiTooltip
                        title="Rough estimate based on full-time work (8h/day) with 5 weeks of vacation during June/July"
                        arrow
                      >
                        <span>Expected Hours</span>
                      </MuiTooltip>
                    </TableCell>
                    {Array.from({ length: 12 }, (_, month) => (
                      <TableCell key={month} align="right">
                        {month >= getFirstReportedMonth(data.monthlyTotals, year) ? expectedWorkDays[month] * 8 : '-'}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>Cumulative Expected</TableCell>
                    {Array.from({ length: 12 }, (_, month) => {
                      const firstMonth = getFirstReportedMonth(data.monthlyTotals, year);
                      const cumulative =
                        month >= firstMonth
                          ? Array.from(
                              { length: month - firstMonth + 1 },
                              (_, i) => expectedWorkDays[i + firstMonth] * 8
                            ).reduce((sum, hours) => sum + hours, 0)
                          : null;
                      return (
                        <TableCell key={month} align="right">
                          {cumulative !== null ? cumulative : '-'}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  <TableRow>
                    <TableCell>Actual Hours</TableCell>
                    {Array.from({ length: 12 }, (_, month) => {
                      const monthlyTotal = Object.values(data.monthlyTotals[year]?.[month] || {})
                        .filter((entry) => entry.type === 'WORK')
                        .reduce((sum, entry) => sum + entry.hours, 0);
                      return (
                        <TableCell
                          key={month}
                          align="right"
                          sx={{
                            color: monthlyTotal < expectedWorkDays[month] * 8 ? 'error.main' : 'success.main',
                          }}
                        >
                          {monthlyTotal ? monthlyTotal.toFixed(1) : '-'}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  <TableRow>
                    <TableCell>Cumulative Actual</TableCell>
                    {Array.from({ length: 12 }, (_, month) => {
                      const firstMonth = getFirstReportedMonth(data.monthlyTotals, year);
                      const isFirstYear = year === Math.max(...Object.keys(data.monthlyTotals).map(Number));
                      const shouldSkipMonth = isFirstYear && month === firstMonth;

                      if (!shouldSkipMonth && month >= firstMonth) {
                        const cumulative = Array.from({ length: month - firstMonth + 1 }, (_, i) =>
                          Object.values(data.monthlyTotals[year]?.[i + firstMonth] || {})
                            .filter((entry) => entry.type === 'WORK')
                            .reduce((sum, entry) => sum + entry.hours, 0)
                        ).reduce((sum, hours) => sum + hours, 0);

                        const expectedCumulative = Array.from(
                          { length: month - firstMonth + 1 },
                          (_, i) => expectedWorkDays[i + firstMonth] * 8
                        ).reduce((sum, hours) => sum + hours, 0);

                        const percentage = expectedCumulative > 0 ? (cumulative / expectedCumulative) * 100 : 0;

                        return (
                          <TableCell
                            key={month}
                            align="right"
                            sx={{
                              color: cumulative < expectedCumulative ? 'error.main' : 'success.main',
                            }}
                          >
                            {cumulative > 0 ? (
                              <div>
                                <div>{cumulative.toFixed(1)}</div>
                                <Typography
                                  component="div"
                                  sx={{
                                    fontSize: '0.75em',
                                    color: 'text.secondary',
                                  }}
                                >
                                  ({percentage.toFixed(1)}%)
                                </Typography>
                              </div>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={month} align="right">
                          -
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
    </>
  );
};

// Helper function to get week number
function getWeekNumber(date) {
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
}
