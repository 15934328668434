import React, { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SyncIcon from '@mui/icons-material/Sync';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { backend } from '../components/user.jsx';
import { config } from '../components/config.jsx';

function toHoursMinutes(totalSeconds) {
  const totalMinutes = Math.floor(totalSeconds / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const seconds = Math.floor(totalSeconds % 60);

  return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
}

export function FortnoxLayout() {
  const integrations = useLoaderData();
  const [syncResult, setSyncResult] = useState(0);
  const [syncInfo, setSyncInfo] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const sync = async (integration) => {
    setSyncResult(1);
    setSyncInfo('Sync started for ' + integration.name);
    try {
      const response = await backend.post(integration.syncUrl);
      if (response.status === 200) {
        setSyncResult(0);
        window.location.reload(false);
      } else {
        throw new Error('Sync failed');
      }
    } catch (error) {
      console.error(error);
      setSyncResult(-1);
      setSyncInfo('Sync failed for ' + integration.name);
    }
  };

  const login = () => {
    window.location.href = config.dusk_url + '/fnx/login';
  };

  for (let i = 0; i < integrations.length; i++) {
    integrations[i].id = uuidv4();
  }

  return (
    <>
      {syncResult === 1 && <Alert severity="info">{syncInfo}</Alert>}
      {syncResult === -1 && <Alert severity="error">{syncInfo}</Alert>}
      <TableContainer component={Paper} sx={{ padding: '0 16px' }}>
        <Table sx={{ minWidth: isMobile ? 'auto' : 650 }} aria-label="integrations table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Company</TableCell>
              {!isMobile && (
                <>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    Last sync
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    Number of syncs
                  </TableCell>
                </>
              )}
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                Sync now
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrations.map((integration) => (
              <React.Fragment key={integration.id}>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {integration.name}
                  </TableCell>
                  {!isMobile && (
                    <>
                      <TableCell align="right">
                        {integration.lastSyncSeconds === -1
                          ? 'Not synced'
                          : toHoursMinutes(integration.lastSyncSeconds)}
                      </TableCell>
                      <TableCell align="right">{integration.count}</TableCell>
                    </>
                  )}
                  <TableCell align="center">
                    {integration.lastSyncSeconds !== -1 && (
                      <IconButton color="primary" onClick={() => sync(integration)} aria-label="sync">
                        <SyncIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
                {isMobile && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <strong>Last sync:</strong>{' '}
                      {integration.lastSyncSeconds === -1 ? 'Not synced' : toHoursMinutes(integration.lastSyncSeconds)}
                      <br />
                      <strong>Number of syncs:</strong> {integration.count}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <div style={{ padding: '0 16px' }}>
        <Button variant="contained" onClick={login}>
          Login and initiate sync
        </Button>
      </div>
    </>
  );
}

export const fortnoxLoader = async () => {
  try {
    const response = await backend.get('/fortnox');
    if (response.status !== 200) {
      throw new Response('Failed to fetch fortnox data', {
        status: response.status,
        statusText: response.statusText,
      });
    }
    return response.data;
  } catch (err) {
    if (err?.status) {
      throw new Response('', { status: err.status });
    }
    throw err;
  }
};

